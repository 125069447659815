// src/data/planData.js
const plansData = {
    threeYearPlans:[
    {
      id: 1,
      title: "WordPress Starter",
      description: "The ideal WP Hosting for  Website!",
      price: "$4.39",
      originalPrice: "$4.39/mo",
      save: "Save 30%",
      features: [
        "1 Website",
        "10 GB NVMe SSD",
        "1 GB RAM",
        "3 Email",
        "150 GB Bandwidth",
        "5 Subdomain",
        "DirectAdmin Panel",
        "LiteSpeed",
        "PreInstalled WordPress",
        "Support - Chat, Ticket & Email",
        "Softaculous",
      ],
      url: "https://my.qloudhost.com/store/wordpress-hosting/wp-starter?billingcycle=triennially",
    },
    {
      id: 2,
      title: "WordPress Business",
      description: "More room to grow your business.",
      price: "$6.96",
      originalPrice: "$6.96/mo",
      save: "Save 30%",
      features: [
        "2 Websites",
        "20 GB NVMe SSD",
        "2 GB RAM",
        "5 Email",
        "300 GB Bandwidth",
        "10 Subdomain",
        "DirectAdmin Panel",
        "LiteSpeed",
        "PreInstalled WordPress",
        "Support - Chat, Ticket & Email",
        "Softaculous",
      ],
      url: "https://my.qloudhost.com/store/wordpress-hosting/wp-business?billingcycle=triennially&currency=1",
    },
    {
      id: 3,
      title: "WordPress Premium",
      description: "Optimized for high-traffic sites.",
      price: "$11.89",
      originalPrice: "$11.89/mo",
      save: "Save 30%",
      features: [
        "10 Websites",
        "50 GB NVMe SSD",
        "3 GB RAM",
        "15 Email",
        "500 GB Bandwidth",
        "20 Subdomain",
        "DirectAdmin Panel",
        "LiteSpeed",
        "PreInstalled WordPress",
        "Support - Chat, Ticket & Email",
        "Softaculous",
      ],
      url: "https://my.qloudhost.com/store/wordpress-hosting/wp-premium?billingcycle=triennially&currency=1",
    }
  ],
  oneYearPlans:[
    {
      id: 4,
      title: "WordPress Starter",
      description: "The ideal WP Hosting for  Website!",
      price: "$5.02",
      originalPrice: "$5.02/mo",
      save: "Save 20%",
      features: [
        "1 Website",
        "10 GB NVMe SSD",
        "1 GB RAM",
        "3 Email",
        "150 GB Bandwidth",
        "5 Subdomain",
        "DirectAdmin Panel",
        "LiteSpeed",
        "PreInstalled WordPress",
        "Support - Chat, Ticket & Email",
        "Softaculous",
      ],
      url: "https://my.qloudhost.com/store/wordpress-hosting/wp-starter?billingcycle=annually",
    },
    {
      id: 5,
      title: "WordPress Business",
      description: "More room to grow your business.",
      price: "$7.95",
      originalPrice: "$7.95/mo",
      save: "Save 20%",
      features: [
        "2 Websites",
        "20 GB NVMe SSD",
        "2 GB RAM",
        "5 Email",
        "300 GB Bandwidth",
        "10 Subdomain",
        "DirectAdmin Panel",
        "LiteSpeed",
        "PreInstalled WordPress",
        "Support - Chat, Ticket & Email",
        "Softaculous",
      ],
      url: "https://my.qloudhost.com/store/wordpress-hosting/wp-business?billingcycle=annually&currency=1",
    },
    {
      id: 6,
      title: "WordPress Premium",
      description: "Optimized for high-traffic sites.",
      price: "$13.59",
      originalPrice: "$13.59/mo",
      save: "Save 20%",
      features: [
        "10 Websites",
        "50 GB NVMe SSD",
        "3 GB RAM",
        "15 Email",
        "500 GB Bandwidth",
        "20 Subdomain",
        "DirectAdmin Panel",
        "LiteSpeed",
        "PreInstalled WordPress",
        "Support - Chat, Ticket & Email",
        "Softaculous",
      ],
      url: "https://my.qloudhost.com/store/wordpress-hosting/wp-premium?billingcycle=annually&currency=1",
    }
  ],
  oneMonthPlans:[
    {
      id: 7,
      title: "WordPress Starter",
      description: "The ideal WP Hosting for Website!",
      price: "$6.28",
      originalPrice: "$6.28/mo",
      save: "Save 0%",
      features: [
        "1 Website",
        "10 GB NVMe SSD",
        "1 GB RAM",
        "3 Email",
        "150 GB Bandwidth",
        "5 Subdomain",
        "DirectAdmin Panel",
        "LiteSpeed",
        "PreInstalled WordPress",
        "Support - Chat, Ticket & Email",
        "Softaculous",
      ],
      url: "https://my.qloudhost.com/store/wordpress-hosting/wp-starter?billingcycle=monthly",
    },
    {
      id: 8,
      title: "WordPress Business",
      description: "More room to grow your business.",
      price: "$6.94",
      originalPrice: "$9.94/mo",
      save: "Save 0%",
      features: [
        "2 Websites",
        "20 GB NVMe SSD",
        "2 GB RAM",
        "5 Email",
        "300 GB Bandwidth",
        "10 Subdomain",
        "DirectAdmin Panel",
        "LiteSpeed",
        "PreInstalled WordPress",
        "Support - Chat, Ticket & Email",
        "Softaculous",
      ],
      url: "https://my.qloudhost.com/store/wordpress-hosting/wp-business?billingcycle=monthly",
    },
    {
      id: 9,
      title: "WordPress Premium",
      description: "Optimized for high-traffic sites.",
      price: "$16.98",
      originalPrice: "$16.98/mo",
      save: "Save 0%",
      features: [
        "10 Websites",
        "50 GB NVMe SSD",
        "3 GB RAM",
        "15 Email",
        "500 GB Bandwidth",
        "20 Subdomain",
        "DirectAdmin Panel",
        "LiteSpeed",
        "PreInstalled WordPress",
        "Support - Chat, Ticket & Email",
        "Softaculous",
      ],
      url: "https://my.qloudhost.com/store/wordpress-hosting/wp-premium?billingcycle=monthly",
    }
  ]
};
  
  export default plansData;
  