import React from 'react';
import { Link } from 'react-router-dom';
import VpsPlan from '../../commonComponent/vpsPlan';



const DmcaIgnoredVpsPlan = () => {

  return (
    <div className="hosting-plan mb-5">
    <div className="container plan-sec mt-5">
      <h2 className="text-center m-auto mb-3">Best Cheap DMCA Ignored VPS Hosting Plans</h2>
      <p className="text-center mb-5 planHead-con m-auto">Get unbeatable performance with our Best Cheap offshore DMCA Ignored VPS hosting Servers without paying any extra bucks! Pay only for what you use!
      Pay only for what you use</p>
      
      <VpsPlan/>
      
      <div className="text-center mt-3">
        <span className="consult">Looking for more power? Check our DMCA <Link to="" className=" fw-bold">Ignored OffShore Dedicated Server </Link>Plans</span>
      </div>
    </div>
    </div>
  );
};

export default DmcaIgnoredVpsPlan
