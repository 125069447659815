// vpsPlansData.js

const vpsPlanData ={ 
    oneYearPlans:[
    {
      id: 1,
      name: "VPS ENTRY",
      price: "$18.99",
      yearlyPrice: "$18.99/mo",
      save: "Save 14%",
    //   freeTooltip: "+1 Month Free",
    // tooltipContent:  `<h5 class='tooltip-Head text-center'>Black Friday Cyber Monday Deal</h5>Signup for 1 year and get additional 1 Month for Free.`,
      features: [
        "2 CPU",
        "4 GB RAM",
        "50 GB NVMe SSD",
        "1 TB Bandwidth",
        "Free Control Panel*",
        "Hosted in Netherlands",
      ],
      url: "https://my.qloudhost.com/index.php?rp=/store/vps/entry-plan&billingcycle=annually",
    },
    {
      id: 2,
      name: "VPS VALUE",
      price: "$46.99",
      yearlyPrice: "$46.99/mo",
      save: "Save 14%",
    //   freeTooltip: "+1 Month Free",
    // tooltipContent:  `<h5 class='tooltip-Head text-center'>Black Friday Cyber Monday Deal</h5>Signup for 1 year and get additional 1 Month for Free.`,
      features: [
        "4 CPU",
        "8 GB RAM",
        "120 GB NVMe SSD",
        "1.75 TB Bandwidth",
        "Free Control Panel*",
        "Hosted in Netherlands",
      ],
      url: "https://my.qloudhost.com/index.php?rp=/store/vps/value-plan&billingcycle=annually",
      popular: true,
    },
    {
      id: 3,
      name: "VPS BUSINESS",
      price: "$55.99",
      yearlyPrice: "$55.99/mo",
      save: "Save 14%",
    //   freeTooltip: "+1 Month Free",
    // tooltipContent:  `<h5 class='tooltip-Head text-center'>Black Friday Cyber Monday Deal</h5>Signup for 1 year and get additional 1 Month for Free.`,
      features: [
        "6 CPU",
        "12 GB RAM",
        "150 GB NVMe SSD",
        "2 TB Bandwidth",
        "Free Control Panel*",
        "Hosted in Netherlands",
      ],
      url: "https://my.qloudhost.com/index.php?rp=/store/vps/business-plan&billingcycle=annually",
    },
    {
      id: 4,
      name: "VPS ENTERPRISE",
      price: "$75.99",
      yearlyPrice: "$75.99/mo",
      save: "Save 14%",
    //   freeTooltip: "+1 Month Free",
    // tooltipContent:  `<h5 class='tooltip-Head text-center'>Black Friday Cyber Monday Deal</h5>Signup for 1 year and get additional 1 Month for Free.`,
      features: [
        "8 CPU",
        "16 GB RAM",
        "200 GB NVMe SSD",
        "2.5 TB Bandwidth",
        "Free Control Panel*",
        "Hosted in Netherlands",
      ],
      url: "https://my.qloudhost.com/index.php?rp=/store/vps/enterprise-plan&billingcycle=annually",
    }
],

sixMonthPlans:[
    {
      id: 5,
      name: "VPS ENTRY",
      price: "$19.99",
      yearlyPrice: "$19.99/mo",
      save: "Save 9%",
      features: [
        "2 CPU",
        "4 GB RAM",
        "50 GB NVMe SSD",
        "1 TB Bandwidth",
        "Free Control Panel*",
        "Hosted in Netherlands",
      ],
      url: "https://my.qloudhost.com/index.php?rp=/store/vps/entry-plan&billingcycle=semiannually",
    },
    {
      id: 6,
      name: "VPS VALUE",
      price: "$48.99",
      yearlyPrice: "$48.99/mo",
      save: "Save 9%",
      features: [
        "4 CPU",
        "8 GB RAM",
        "120 GB NVMe SSD",
        "1.75 TB Bandwidth",
        "Free Control Panel*",
        "Hosted in Netherlands",
      ],
      url: "https://my.qloudhost.com/index.php?rp=/store/vps/value-plan&billingcycle=semiannually",
      popular: true,
    },
    {
      id: 7,
      name: "VPS BUSINESS",
      price: "$58.99",
      yearlyPrice: "$58.99/mo",
      save: "Save 9%",
      features: [
        "6 CPU",
        "12 GB RAM",
        "150 GB NVMe SSD",
        "2 TB Bandwidth",
        "Free Control Panel*",
        "Hosted in Netherlands",
      ],
      url: "https://my.qloudhost.com/index.php?rp=/store/vps/business-plan&billingcycle=semiannually",
    },
    {
      id: 8,
      name: "VPS ENTERPRISE",
      price: "$79.98",
      yearlyPrice: "$79.98/mo",
      save: "Save 9%",
      features: [
        "8 CPU",
        "16 GB RAM",
        "200 GB NVMe SSD",
        "2.5 TB Bandwidth",
        "Free Control Panel*",
        "Hosted in Netherlands",
      ],
      url: "https://my.qloudhost.com/index.php?rp=/store/vps/enterprise-plan&billingcycle=semiannually",
    }
],

threeMonthPlans:[
    {
      id: 9,
      name: "VPS ENTRY",
      price: "$20.99",
      yearlyPrice: "$20.99/mo",
      save: "Save 5%",
      features: [
        "2 CPU",
        "4 GB RAM",
        "50 GB NVMe SSD",
        "1 TB Bandwidth",
        "Free Control Panel*",
        "Hosted in Netherlands",
      ],
      url: "https://my.qloudhost.com/index.php?rp=/store/vps/entry-plan&billingcycle=quarterly",
    },
    {
      id: 10,
      name: "VPS VALUE",
      price: "$51.99",
      yearlyPrice: "$51.99/mo",
      save: "Save 5%",
      features: [
        "4 CPU",
        "8 GB RAM",
        "120 GB NVMe SSD",
        "1.75 TB Bandwidth",
        "Free Control Panel*",
        "Hosted in Netherlands",
      ],
      url: "https://my.qloudhost.com/index.php?rp=/store/vps/value-plan&billingcycle=quarterly",
      popular: true,
    },
    {
      id: 11,
      name: "VPS BUSINESS",
      price: "$62.99",
      yearlyPrice: "$62.99/mo",
      save: "Save 5%",
      features: [
        "6 CPU",
        "12 GB RAM",
        "150 GB NVMe SSD",
        "2 TB Bandwidth",
        "Free Control Panel*",
        "Hosted in Netherlands",
      ],
      url: "https://my.qloudhost.com/index.php?rp=/store/vps/business-plan&billingcycle=quarterly",
    },
    {
      id: 12,
      name: "VPS ENTERPRISE",
      price: "$84.99",
      yearlyPrice: "$84.99/mo",
      save: "Save 5%",
      features: [
        "8 CPU",
        "16 GB RAM",
        "200 GB NVMe SSD",
        "2.5 TB Bandwidth",
        "Free Control Panel*",
        "Hosted in Netherlands",
      ],
      url: "https://my.qloudhost.com/index.php?rp=/store/vps/enterprise-plan&billingcycle=quarterly",
    }
],

oneMonthPlans:[
    {
      id: 13,
      name: "VPS ENTRY",
      price: "$21.99",
      yearlyPrice: "$21.99/mo",
      save: "Save 0%",
      features: [
        "2 CPU",
        "4 GB RAM",
        "50 GB NVMe SSD",
        "1 TB Bandwidth",
        "Free Control Panel*",
        "Hosted in Netherlands",
      ],
      url: "https://my.qloudhost.com/index.php?rp=/store/vps/entry-plan&billingcycle=monthly",
    },
    {
      id: 14,
      name: "VPS VALUE",
      price: "$54.99",
      yearlyPrice: "$54.99/mo",
      save: "Save 0%",
      features: [
        "4 CPU",
        "8 GB RAM",
        "120 GB NVMe SSD",
        "1.75 TB Bandwidth",
        "Free Control Panel*",
        "Hosted in Netherlands",
      ],
      url: "https://my.qloudhost.com/index.php?rp=/store/vps/value-plan&billingcycle=monthly",
      popular: true,
    },
    {
      id: 15,
      name: "VPS BUSINESS",
      price: "$65.99",
      yearlyPrice: "$65.99/mo",
      save: "Save 0%",
      features: [
        "6 CPU",
        "12 GB RAM",
        "150 GB NVMe SSD",
        "2 TB Bandwidth",
        "Free Control Panel*",
        "Hosted in Netherlands",
      ],
      url: "https://my.qloudhost.com/index.php?rp=/store/vps/business-plan&billingcycle=monthly",
    },
    {
      id: 16,
      name: "VPS ENTERPRISE",
      price: "$88.99",
      yearlyPrice: "$88.99/mo",
      save: "Save 0%",
      features: [
        "8 CPU",
        "16 GB RAM",
        "200 GB NVMe SSD",
        "2.5 TB Bandwidth",
        "Free Control Panel*",
        "Hosted in Netherlands",
      ],
      url: "https://my.qloudhost.com/index.php?rp=/store/vps/enterprise-plan&billingcycle=monthly",
    },
]
};

export default vpsPlanData;
