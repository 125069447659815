const offshoreplanData = {
    threeYearPlans: [
      {
        id: 1,
        name: "Basic",
        price: "$3.50",
        samePrice: '$3.50',
        discount: "You Save 30%",
        duration: "3 Year",
      //   freeTooltip: "+3 Months Free",
      // tooltipContent: `<h5 class='tooltip-Head text-center'>Black Friday Cyber Monday Deal</h5>Signup for 3 years and get an additional 3 months for Free.`,
        features: [
          "1 Website",
          "10 GB NVMe SSD",
          "1 GB RAM",
          "3 Email",
          "150 GB Bandwidth",
          "5 Subdomain",
          "DirectAdmin Panel",
          "LiteSpeed",
          "Softaculous 1-click Install",
          "24*7 Support",
          "Security"
        ],
        url: "https://my.qloudhost.com/index.php?rp=/store/shared-hostin/basic&billingcycle=triennially&currency=1",
        popular: false,
      },
      {
        id: 2,
        name: "Pro",
        price: "$5.50",
        samePrice: '$5.50',
        discount: "You Save 30%",
        duration: "3 Year",
      //   freeTooltip: "+3 Months Free",
      // tooltipContent: `<h5 class='tooltip-Head text-center'>Black Friday Cyber Monday Deal</h5>Signup for 3 years and get an additional 3 months for Free.`,
        features: [
          "2 Website",
          "20 GB NVMe SSD",
          "2 GB RAM",
          "5 Email",
          "300 GB Bandwidth",
          "10 Subdomain",
          "DirectAdmin Panel",
          "LiteSpeed",
          "Softaculous 1-click Install",
          "24*7 Support",
          "Security"
        ],
        url: "https://my.qloudhost.com/index.php?rp=/store/shared-hostin/pro&billingcycle=triennially&currency=1",
        popular: true,
      },
      {
        id: 3,
        name: "Grow",
        price: "$9.09",
        samePrice: '$9.09',
        discount: "You Save 30%",
        duration: "3 Year",
      //   freeTooltip: "+3 Months Free",
      // tooltipContent: `<h5 class='tooltip-Head text-center'>Black Friday Cyber Monday Deal</h5>Signup for 3 years and get an additional 3 months for Free.`,
        features: [
          "10 Website",
          "50 GB NVMe SSD",
          "3 GB RAM",
          "15 Email",
          "500 GB Bandwidth",
          "20 Subdomain",
          "DirectAdmin Panel",
          "LiteSpeed",
          "Softaculous 1-click Install",
          "24*7 Support",
          "Security"
        ],
        url: "https://my.qloudhost.com/index.php?rp=/store/shared-hostin/grow&billingcycle=triennially&currency=1",
        popular: false,
      }
    ],
  
    oneYearPlans: [
      {
        id: 4,
        name: "Basic",
        price: "$4.00",
        samePrice: '$4.00',
        discount: "You Save 20%",
        duration: "1 Year",
        features: [
          "1 Website",
          "10 GB NVMe SSD",
          "1 GB RAM",
          "3 Email",
          "150 GB Bandwidth",
          "5 Subdomain",
          "DirectAdmin Panel",
          "LiteSpeed",
          "Softaculous 1-click Install",
          "24*7 Support",
          "Security"
        ],
        url: "https://my.qloudhost.com/index.php?rp=/store/shared-hostin/basic&billingcycle=annually&currency=1",
        popular: false,
      },
      {
        id: 5,
        name: "Pro",
        price: "$6.29",
        samePrice: '$6.29',
        discount: "You Save 20%",
        duration: "1 Year",
        features: [
          "2 Website",
          "20 GB NVMe SSD",
          "2 GB RAM",
          "5 Email",
          "300 GB Bandwidth",
          "10 Subdomain",
          "DirectAdmin Panel",
          "LiteSpeed",
          "Softaculous 1-click Install",
          "24*7 Support",
          "Security"
        ],
        url: "https://my.qloudhost.com/index.php?rp=/store/shared-hostin/pro&billingcycle=annually&currency=1",
        popular: true,
      },
      {
        id: 6,
        name: "Grow",
        price: "$10.39",
        samePrice: '$10.39',
        discount: "You Save 20%",
        duration: "1 Year",
        features: [
          "10 Website",
          "50 GB NVMe SSD",
          "3 GB RAM",
          "15 Email",
          "500 GB Bandwidth",
          "20 Subdomain",
          "DirectAdmin Panel",
          "LiteSpeed",
          "Softaculous 1-click Install",
          "24*7 Support",
          "Security"
        ],
        url: "https://my.qloudhost.com/index.php?rp=/store/shared-hostin/grow&billingcycle=annually&currency=1",
        popular: false,
      }
    ],
  
    oneMonthPlans: [
      {
        id: 7,
        name: "Basic",
        price: "$4.99",
        samePrice: '$4.99',
        discount: "You Save 0%",
        duration: "1 Month",
        features: [
          "1 Website",
          "10 GB NVMe SSD",
          "1 GB RAM",
          "3 Email",
          "150 GB Bandwidth",
          "5 Subdomain",
          "DirectAdmin Panel",
          "LiteSpeed",
          "Softaculous 1-click Install",
          "24*7 Support",
          "Security"
        ],
        url: "https://my.qloudhost.com/index.php?rp=/store/shared-hostin/basic&billingcycle=monthly&currency=1",
        popular: false,
      },
      {
        id: 8,
        name: "Pro",
        price: "$7.86",
        samePrice: '$7.86',
        discount: "You Save 0%",
        duration: "1 Month",
        features: [
          "2 Website",
          "20 GB NVMe SSD",
          "2 GB RAM",
          "5 Email",
          "300 GB Bandwidth",
          "10 Subdomain",
          "DirectAdmin Panel",
          "LiteSpeed",
          "Softaculous 1-click Install",
          "24*7 Support",
          "Security"
        ],
        url: "https://my.qloudhost.com/index.php?rp=/store/shared-hostin/pro&billingcycle=monthly&currency=1",
        popular: true,
      },
      {
        id: 9,
        name: "Grow",
        price: "$12.98",
        samePrice: '$12.98',
        discount: "You Save 0%",
        duration: "1 Month",
        features: [
          "10 Website",
          "50 GB NVMe SSD",
          "3 GB RAM",
          "15 Email",
          "500 GB Bandwidth",
          "20 Subdomain",
          "DirectAdmin Panel",
          "LiteSpeed",
          "Softaculous 1-click Install",
          "24*7 Support",
          "Security"
        ],
        url: "https://my.qloudhost.com/index.php?rp=/store/shared-hostin/grow&billingcycle=monthly&currency=1",
        popular: false,
      }
    ]
  };
  
  export default offshoreplanData;
  